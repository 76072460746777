import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";


export const CityListItem = ({ name, jpg, webp, alt, href }) => {
  return (
    <Link to={href} className="city-thumb">
      <div className="wrapper">
        <p>{name}</p>
        
        <picture>
          <source type="image/webp" srcSet={webp}/>
          <img src={jpg} alt={alt}/>
        </picture>
        <span className="btn btn-outlined">подробнее</span>
      </div>
    </Link>
  )
}


CityListItem.propTypes = {
  name: PropTypes.string.isRequired, 
  jpg: PropTypes.string.isRequired,
  webp: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
}