import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TransportImg1 from "../../../images/transport-1.png";
import TransportImg1Webp from "../../../images/transport-1.png.webp";
import TransportImg2 from "../../../images/transport-2.png";
import TransportImg2Webp from "../../../images/transport-2.png.webp";
import TransportImg3 from "../../../images/transport-3.png";
import TransportImg3Webp from "../../../images/transport-3.png.webp";
import TransportImg4 from "../../../images/transport-4.png";
import TransportImg4Webp from "../../../images/transport-4.png.webp";
import TransportImg5 from "../../../images/transport-5.png";
import TransportImg5Webp from "../../../images/transport-5.png.webp";
import TransportImg6 from "../../../images/transport-6.png";
import TransportImg6Webp from "../../../images/transport-6.png.webp";
import TransportImg7 from "../../../images/transport-7.png";
import TransportImg7Webp from "../../../images/transport-7.png.webp";


export const Vehicles = () => {
  return (
    <Container className={"vehicles-container"}>
      <Row className={"align-items-center"}>
        <Col xs={12} lg={4} xl={5} xxl={6}>
          <h2>Осуществляем чистку сажевых фильтров разных видов транспорта</h2>
        </Col>

        <Col xs={12} lg={8} xl={7} xxl={6}>
          <div className={"vehicles-wrapper"}>
            <ul>
              <li>
                <picture>
                  <source type="image/webp" srcSet={TransportImg1Webp}/>
                  <img src={TransportImg1} alt={"легковой автомобиль"}/>
                </picture>
              </li>

              <li>
                <picture>
                  <source type="image/webp" srcSet={TransportImg2Webp}/>
                  <img src={TransportImg2} alt={"микроавтобус"}/>
                </picture>
              </li>

              <li>
                <picture>
                  <source type="image/webp" srcSet={TransportImg3Webp}/>
                  <img src={TransportImg3} alt={"грузовик"}/>
                </picture>
              </li>

              <li>
                <picture>
                  <source type="image/webp" srcSet={TransportImg4Webp}/>
                  <img src={TransportImg4} alt={"TIR"}/>
                </picture>
              </li>

              <li>
                <picture>
                  <source type="image/webp" srcSet={TransportImg5Webp}/>
                  <img src={TransportImg5} alt={"трактор"}/>
                </picture>
              </li>

              <li>
                <picture>
                  <source type="image/webp" srcSet={TransportImg6Webp}/>
                  <img src={TransportImg6} alt={"поезд"}/>
                </picture>
              </li>

              <li>
                <picture>
                  <source type="image/webp" srcSet={TransportImg7Webp}/>
                  <img src={TransportImg7} alt={"эксковатор"}/>
                </picture>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  )
}