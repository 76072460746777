import * as React from "react";
import PropTypes from "prop-types";
import {useInView} from "react-intersection-observer";


function AboutCert({jpg, webp, alt, href}) {
  const {ref, inView} = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <a href={href} target="_blank" ref={ref}  rel="noreferrer">
      {
        inView &&
        <picture>
          <source type="image/webp" srcSet={webp}/>
          <img src={jpg} alt={alt}/>
        </picture>
      }
    </a>
  )
}

AboutCert.propTypes = {
  jpg: PropTypes.string.isRequired,
  webp: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
}

export default AboutCert;