import * as React from "react";
import Container from "react-bootstrap/Container";


export const WhyUs = () => {
  return (
    <Container className={'whyUs-container'}>
      <h2>Почему мы? <small>Почему стоит почистить сажевый фильтр именно у нас?</small></h2>

      <div className={'items-wrapper'}>
        <p className={'item'}>
          <span className={'num'}>15<i className={'d-none'}> минут</i></span>
          <span className={'title'}>диагностика снятого фильтра в присутствии клиента в течение 15 минут</span>
        </p>

        <p className={'item'}>
          <span className={'num'}>99<i className={'d-none'}> % загрязнений</i></span>
          <span className={'title'}>удаление 99% загрязнений на Европейском оборудовании Total DPF</span>
        </p>

        <p className={'item'}>
          <span className={'num'}>6<i className={'d-none'}> месяцев</i></span>
          <span className={'title'}>гарантийный срок на выполненные работы 6 месяцев или пробег не более 50 000 км</span>
        </p>

        <p className={'item'}>
          <span className={'num'}>1<i className={'d-none'}> час</i></span>
          <span className={'title'}>процесс чистки занимает от 1 до 3 часов</span>
        </p>
      </div>
    </Container>
  )
}