import * as React from "react";
import {Vehicles} from "./Vehicles";
import {Problems} from "./Problems";
import {WhyUs} from "./WhyUs";
import {Modals} from "./Modals";
import {Steps} from "./Steps";
import "./index.scss";


const WhyUsIndex = () => {
    return (
        <section className={'my-5'}>
          <Vehicles/>
          <Problems/>
          <WhyUs/>
          <Modals/>
          <Steps/>
        </section>
    )
}

export default WhyUsIndex;