import * as React from "react";
import RegenerationJpg from "../../../images/regeneration.jpg";
import RegenerationWebp from "../../../images/regeneration.jpg.webp";


function Regeneration() {
  return (
    <>
      <picture>
        <source type="image/webp" srcSet={RegenerationWebp}/>
        <img src={RegenerationJpg} alt="что такое регенерация" className="image-fluid"/>
      </picture>
      <h3>Как работает фильтр?</h3>
      <p>
        Работа DPF заключается в фильтрации и хранении сажи, возникающей при
        работе дизельного двигателя.
      </p>

      <h3>Что делает фильтр, когда в нем накапливается много сажи?</h3>
      <p>
        DPF сжигает ее при высоких температурах, оставляя лишь немного золы.
        Этот процесс и называют регенерацией.
      </p>
      <p>
        Регенерация сжигает излишки сажи в фильтре, уменьшая вредные выбросы
        выхлопных газов и помогает предотвратить черные клубы дыма, которые
        иногда можно увидеть от старых дизельных автомобилей, когда они
        ускоряются.
      </p>
      <p>
        <strong>
          Есть два типа регенерации — активная и пассивная.
        </strong>
      </p>

      <h3>Что такое пассивная регенерация сажевого фильтра?</h3>
      <p>
        Пассивная регенерация происходит во время движения автомобиля под
        воздействием температуры выхлопных газов. Но не все так просто:
        температура нужна довольно высокая, а двигаться придется
        продолжительное время.
      </p>
      <p>
        Чтобы быть уверенным в том, что пассивная регенерация периодически
        происходит, а она должна происходить 2-3 раза в интервале замены
        моторного масла, рекомендуется ездить по автомагистрали минимум 30-45
        минут со скоростью не менее 80 км/ч.
      </p>

      <h3>Рассмотрим условия регенерации чуть подробнее</h3>
      <p>
        Когда фильтр достаточно загрязнен, электронный блок управления
        получает сигнал и предупреждает владельца автомобиля о необходимости
        очистки.
      </p>
      <p>
        Автомобиль может самостоятельно очистить DPF только при определенных
        условиях:
      </p>
      <ul>
        <li>высокая температура отработавших газов (от 500 град. Ц)</li>
        <li>
          работа двигателя с максимальной нагрузкой:
          3000 об/мин на третьей передаче
        </li>
        <li>
          если количество топлива в баке меньше четверти, регенерация не
          запустится. Желательно, чтобы бак был на половину полон, а датчик
          уровня топлива был исправен
        </li>
      </ul>

      <h3>Что такое активная регенерация сажевого фильтра?</h3>
      <p>
        Активная регенерация работает путем впрыска дополнительного топлива,
        чтобы повысить температуру выхлопных газов и сжечь сажу в сажевом
        фильтре. Производится как правило при помощи диагностического
        оборудования, запускающего процесс.
      </p>
      <p>
        В зависимости от автомобиля и конструкции фильтра, принцип запуска
        активной регенерации может быть разным. Очисткой управляют
        расходомер воздуха, датчики температуры отработавших газов,
        установленные до и после фильтра, а также датчик перепада давления в
        сажевом фильтре.
      </p>
      <p>
        Полагаясь на сигналы датчиков электронный блок управления («мозги»)
        подает сигнал для дополнительного впрыска топлива в камеру сгорания,
        частично ограничивает подачу воздуха и прекращает рециркуляцию
        выхлопных газов. Эти условия поднимают температуру выхлопных газов,
        что должно помочь удалить сажу из фильтра.
      </p>

      <h3>Что мешает фильтру очиститься самостоятельно?</h3>
      <p>
        Что может помешать процессу пассивной регенерации? Самое простое —
        езда в городе: короткие расстояния, небольшая скорость, пробки. При
        такой эксплуатации процесс регенерации будет прерываться, что в
        последствии приведет к полному засорению фильтрующего элемента.
      </p>
      <p>
        Также сажевые фильтры довольно чувствительны к качеству моторного
        масла и топлива.
      </p>
      <p>
        При неудачных попытках регенерации или неисправности датчиков,
        дополнительное топливо для прожига фильтра может отправиться в масло.
        Это можно заметить по качеству масла и его уровню, за чем периодически
        лучше следить, особенно если автомобиль пробежал уже более 100 тыс.
        км. Незавершенная регенерация будет возобновляться снова и снова, пока
        не завершится качественно.
      </p>

      <h3>Что произоидет с автомобилем, если забыть про грязный «сажевик»?</h3>
      <p>
        С помощью сигналов на приборной панели автомобиль предупреждает
        владельца о том, что фильтр забился. Если регенерация не происходит
        долгое время, система переведет автомобиль в аварийный режим и
        ограничит некоторые из его функций.
      </p>
      <p>
        Так, автомобиль с забитым фильтром дает о себе знать в первую очередь
        тем, что теряет нормальные тягово-динамические характеристики («не
        едет»). Двигатель может работать с заметными перебоями. Активируется
        аварийное состояние электронной системы управления. Уровень масла в
        двигателе начнет увеличиваться, в итоге это все может привести к тому,
        что мотор «застучит».
      </p>
    </>
  )
}

export default Regeneration;