import * as React from "react"
import Container from "react-bootstrap/Container"
import {useInView} from 'react-intersection-observer';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Gallery1Img from '../../../images/gallery-1.jpg';
import Gallery1ImgWebp from '../../../images/gallery-1.jpg.webp';
import Gallery2Img from '../../../images/gallery-2.jpg';
import Gallery2ImgWebp from '../../../images/gallery-2.jpg.webp';
import Gallery3Img from '../../../images/gallery-3.jpg';
import Gallery3ImgWebp from '../../../images/gallery-3.jpg.webp';
import Gallery4Img from '../../../images/gallery-4.jpg';
import Gallery4ImgWebp from '../../../images/gallery-4.jpg.webp';
import Gallery5Img from '../../../images/gallery-5.jpg';
import Gallery5ImgWebp from '../../../images/gallery-5.jpg.webp';
import "./work-process.scss"

export const WorkProcess = () => {
  const [visibleSlides, setVisibleSlides] = React.useState(4)
  const {ref, inView} = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const two = window.matchMedia("(max-width: 991px)");
      if (two.matches) {
        setVisibleSlides(1);
      }
      two.addEventListener('change', (e) => {
        if (e.matches) {
          setVisibleSlides(1);
        }
      })

      var three = window.matchMedia("(min-width: 992px)");
      if (three.matches) {
        setVisibleSlides(4);
      }
      three.addEventListener('change', (e) => {
        if (e.matches) {
          setVisibleSlides(4);
        }
      })
    }
  }, [])


  return (
    <section id="process" className="workprocess-section">
      <Container>
        <h2 className="text-center mt-0 mb-5">Наши работы</h2>
        <CarouselProvider
          naturalSlideWidth={420}
          naturalSlideHeight={280}
          totalSlides={5}
          visibleSlides={visibleSlides}
          infinite={true}
        >
          <Slider>
            <Slide index={0}>
              <div className={"image-wrapper"}>
                <picture>
                  <source srcSet={Gallery1ImgWebp} type="image/webp"/>
                  <img src={Gallery1Img} alt="" className={"image-fluid"}/>
                </picture>
              </div>
            </Slide>
            <Slide index={1}>
              <div className={"image-wrapper"}>
                <picture>
                  <source srcSet={Gallery2ImgWebp} type="image/webp"/>
                  <img src={Gallery2Img} alt="" className={"image-fluid"}/>
                </picture>
              </div>
            </Slide>
            <Slide index={2}>
              <div className={"image-wrapper"}>
                <picture>
                  <source srcSet={Gallery3ImgWebp} type="image/webp"/>
                  <img src={Gallery3Img} alt="" className={"image-fluid"}/>
                </picture>
              </div>
            </Slide>
            <Slide index={3}>
              <div className={"image-wrapper"}>
                <picture>
                  <source srcSet={Gallery4ImgWebp} type="image/webp"/>
                  <img src={Gallery4Img} alt="" className={"image-fluid"}/>
                </picture>
              </div>
            </Slide>
            <Slide index={4}>
              <div className={"image-wrapper"}>
                <picture>
                  <source srcSet={Gallery5ImgWebp} type="image/webp"/>
                  <img src={Gallery5Img} alt="" className={"image-fluid"}/>
                </picture>
              </div>
            </Slide>
          </Slider>
          <DotGroup/>
        </CarouselProvider>
      </Container>
    </section>
  )
}
