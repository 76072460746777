import * as React from "react";
import { Container } from "react-bootstrap";
import "./styles.scss";

export const AdBlue = () => {
  return (
    <div className="adblue">
      <Container>
        <h3>Не упустите шанс!</h3>

        <p>
          Получите <em>скидку 10%</em> на <a 
            href="https://adblue.smturbo.by/?utm_source=dpfbel&utm_medium=banner&utm_campaign=adblue"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>AdBlue®</strong>
          </a> любого объема при записи на услугу
          «чистка сажевого фильтра или катализатора» в SMTurbo
        </p>
      </Container>
    </div>
  )
}