import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Step1Img from '../../../images/step-1.jpg';
import Step1ImgWebp from '../../../images/step-1.jpg.webp';
import Step2Img from '../../../images/step-2.jpg';
import Step2ImgWebp from '../../../images/step-2.jpg.webp';
import Step3Img from '../../../images/step-3.jpg';
import Step3ImgWebp from '../../../images/step-3.jpg.webp';
import Step4Img from '../../../images/step-4.jpg';
import Step4ImgWebp from '../../../images/step-4.jpg.webp';

export const Steps = () => {
  return (
    <Container className={'whyUsSteps-container'}>
      <Row>
        <Col xs={12} lg={6}>
          <h3>Ход работы <small>Чистка при таком методе проходит в 4 этапа:</small></h3>
        </Col>

        <Col xs={12} lg={6}>
          <ul>
            <li>
              <i>01</i>
              <span>Визуальный осмотр на наличие повреждений</span>

              <picture>
                <source srcSet={Step1ImgWebp} type="image/webp"/>
                <img src={Step1Img} alt=""/>
              </picture>
            </li>

            <li>
              <i>02</i>
              <span>Проверка фильтра в специализированном стенде с целью оценки загрязнения</span>

              <picture>
                <source srcSet={Step2ImgWebp} type="image/webp"/>
                <img src={Step2Img} alt=""/>
              </picture>
            </li>

            <li>
              <i>03</i>
              <span>4-х этапная мойка с использованием специального раствора</span>

              <picture>
                <source srcSet={Step3ImgWebp} type="image/webp"/>
                <img src={Step3Img} alt=""/>
              </picture>
            </li>

            <li>
              <i>04</i>
              <span>Сушка с дальнейшей печатью полученных результатов</span>

              <picture>
                <source srcSet={Step4ImgWebp} type="image/webp"/>
                <img src={Step4Img} alt=""/>
              </picture>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};