import * as React from "react";
import Container from "react-bootstrap/Container";
import { CityListItem } from "./CityListItem";
import VitebskImg from "../images/gorod-vitebsk-sm.jpg";
import VitebskImgWebp from "../images/gorod-vitebsk-sm.jpg.webp";
import MogilevImg from "../images/gorod-mogilev-sm.jpg";
import MogilevImgWebp from "../images/gorod-mogilev-sm.jpg.webp";
import GomelImg from "../images/gorod-gomel-sm.jpg";
import GomelImgWebp from "../images/gorod-gomel-sm.jpg.webp";
import BrestImg from "../images/gorod-brest-sm.jpg";
import BrestImgWebp from "../images/gorod-brest-sm.jpg.webp";
import GrodnoImg from "../images/gorod-grodno-sm.jpg";
import GrodnoImgWebp from "../images/gorod-grodno-sm.jpg.webp";
import "./cityList.scss";

export const CityList = () => {
  return (
    <Container className="mb-5">
      <h2 className="city-list__header">Доставка в Минск <small>из любой точки Беларуси</small></h2>

      <div className="city-list">
        <CityListItem name="Витебск" href="/vitebsk/" jpg={VitebskImg} webp={VitebskImgWebp} alt="чистка сажевого фильтра в Витебске" />
        <CityListItem name="Могилёв" href="/mogilev/" jpg={MogilevImg} webp={MogilevImgWebp} alt="чистка сажевого фильтра в Могилёве" />
        <CityListItem name="Гомель" href="/gomel/" jpg={GomelImg} webp={GomelImgWebp} alt="чистка сажевого фильтра в Гомеле" />
        <CityListItem name="Брест" href="/brest/" jpg={BrestImg} webp={BrestImgWebp} alt="чистка сажевого фильтра в Бресте" />
        <CityListItem name="Гродно" href="/grodno/" jpg={GrodnoImg} webp={GrodnoImgWebp} alt="чистка сажевого фильтра в Гродно" />
      </div>
    </Container>
  );
}