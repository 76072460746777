import * as React from "react";

function CleaningProcess() {
  return (
    <>
      <h3>Первый этап: предварительный осмотр</h3>
      <p>
        Проверка целостности фильтрующего элемента: наличие трещин, запаянных
        или раскрошившихся фрагментов.
        Если фильтр сильно поврежден, он не подлежит очистке, его необходимо
        заменить.
      </p>

      <h3>
        Второй этап: монтаж фильтра в устройстве с целью оценки противодавления
      </h3>
      <p>
        Фильтр закрепляется и герметично закрывается в устройстве.
        Проходит тест противодавления и скорости прохождения потока воздуха
        через фильтрующий элемент.
        Затем фильтр наполняется специальным чистящим раствором.
        Чистящее средство помогает растворить загрязнения фильтра, а также
        защищает его структуру.
      </p>

      <h3>Третий этап: мойка</h3>
      <p>
        Жидкостная чистка FAP фильтра осуществляется в 4 этапа:
      </p>

      <ul>
        <li>
          Чистка по направлению выхлопных газов
        </li>
        <li>
          Чистка против направления выхлопных газов
        </li>
        <li>
          Повторная чистка:
          цикл чистки в двух направлениях повторяется еще раз
        </li>
      </ul>

      <p>
        На каждом этапе очистки частицы, выходящие из фильтра, анализируются,
        благодаря чему можно определить причину загрязнения, а также
        отрегулировать продолжительность процесса.
        Благодаря идеально подобранным к каждому фильтру программам, происходит
        соответствующее размеру наполнение очищающей жидкостью, а также
        подбирается определенное давление ударов сжатого воздуха, что полностью
        защищает фильтр от механических повреждений.
      </p>

      <p>
        Чистка фильтра осуществляется в конкретной последовательности наполнения
        и
        течения специального раствора, а также ударов сжатого воздуха.
        Температура
        раствора – 70 градусов.
      </p>

      <h3>Четвертый этап: сушка</h3>
      <ul>
        <li>Фильтр просушивается в двух направлениях</li>
        <li>После полного высыхания фильтр охлаждается</li>
        <li>Затем вновь проводится тест противодавления и скорости течения</li>
        <li>Печатается отчет, в котором можно проследить начальные параметры
          фильтра и конечные по результатам очистки
        </li>
      </ul>

      <p>
        <strong>
          На установке можно очистить каталитические нейтрализаторы (CAT), для
          которых также ведется особая настройка очистки.
        </strong>
      </p>
    </>
  )
}

export default CleaningProcess;