import * as React from "react";
import Container from "react-bootstrap/Container";
import ProblemIcon1 from "../../../images/problem-1.svg"
import ProblemIcon2 from "../../../images/problem-2.svg"
import ProblemIcon3 from "../../../images/problem-3.svg"
import ProblemIcon4 from "../../../images/problem-4.svg"
import ProblemIcon5 from "../../../images/problem-5.svg"


export const Problems = () => {
    return (
        <Container className={"problems-container"}>
          <h3>Вам точно нужно к нам, если</h3>

          <div className={"problems-wrapper"}>
            <ul>
              <li>
                <span className={"problems-img-wrapper"}>
                  <img src={ProblemIcon1} alt={""}/>
                </span>

                <span>
                  двигатель работает <strong>неравномерно</strong>
                  <small>(особенно заметно на холостых оборотах)</small>
                </span>
              </li>

              <li>
                <span className={"problems-img-wrapper"}>
                  <img src={ProblemIcon2} alt={""}/>
                </span>

                <span>
                  существенно увеличился <strong>расход топлива</strong>
                </span>
              </li>

              <li>
                <span className={"problems-img-wrapper"}>
                  <img src={ProblemIcon3} alt={""}/>
                </span>

                <span>
                  без видимых причин поднялся <strong>уровень масла</strong>
                </span>
              </li>

              <li>
                <span className={"problems-img-wrapper"}>
                  <img src={ProblemIcon4} alt={""}/>
                </span>

                <span>
                  появляются <strong>проблемы</strong> с запуском двигателя и <strong>сторонние шумы</strong>
                </span>
              </li>

              <li>
                <span className={"problems-img-wrapper"}>
                  <img src={ProblemIcon5} alt={""}/>
                </span>

                <span>
                  <strong>снижение</strong> мощности и <strong>увеличение</strong> частоты прожигов
                </span>
              </li>
            </ul>
          </div>

          <p className={"problems-description"}>
            Если вы сталкиваетесь с проблемами при запуске регенерации,
            рекомендуется для начала обратиться к нам на диагностику.

            Ведь принудительные прожиги могут сработать не всегда, а если с ними переборщить,
            то под давлением фильтр может потрескаться и даже разрушиться.
          </p>
        </Container>
    )
}