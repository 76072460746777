import * as React from "react";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Youtube from "./Youtube";
import CleaningProcess from "./CleaningProcess";
import Regeneration from "./Regeneration";
import "./info.scss";


function Info() {
  const ref = React.useRef();

  const handleClick = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      console.log();
      window.scrollTo({top: rect.top + window.scrollY  - 60, behavior: "instant"});
      // ref.current.scrollIntoView();
    }
  }
  return (
    <section id={"section-info"} className={"section-info"} ref={ref}>
      <Container>
        <Accordion defaultActiveKey="howToCleaningYoutube" flush>
          <Accordion.Item eventKey="regeneration">
            <Accordion.Header onClick={handleClick}>
              Что такое регенерация?
            </Accordion.Header>
            <Accordion.Body>
              <Regeneration/>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="howToCleaning">
            <Accordion.Header onClick={handleClick}>
              Как происходит чистка сажевого фильтра?
            </Accordion.Header>
            <Accordion.Body>
              <CleaningProcess/>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="howToCleaningYoutube">
            <Accordion.Header onClick={handleClick}>
              Как происходит чистка сажевого фильтра (ВИДЕО)?
            </Accordion.Header>
            <Accordion.Body className="section-video">
              <Youtube/>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  )
}

export default Info;