import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";


export const Modals = () => {
  const [show, setShow] = React.useState(0);

  const handleClose = () => setShow(0);

  return (
    <div className={'whyUsModals'}>
      <Container className={'whyUsModalsContainer'}>
        <Row>
          <Col xs={12} lg={4}>
            <div className={'modals-btn'} role={'button'} onClick={() => setShow(1)}>
              Причины забитого сажевого фильтра
            </div>

            <Modal show={show === 1} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Основные причины, по которым сажевый фильтр забивается.</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul>
                  <li>Использование некачественного топлива</li>
                  <li>Некачественный сажевый фильтр или катализатор</li>
                  <li>Проблемы с двигателем</li>
                  <li>Неисправность форсунок, турбокомпрессора или системы рециркуляции отработавших газов</li>
                  <li>Путешествия на короткие расстояния без прогрева двигателя</li>
                </ul>
              </Modal.Body>
            </Modal>
          </Col>

          <Col xs={12} lg={4}>
            <div className={'modals-btn'} role={'button'} onClick={() => setShow(2)}>
              Метод очистки
            </div>

            <Modal show={show === 2} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Наш метод очистки</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Наш метод очистки гарантирует удаление до 99% загрязнений,
                  возникающих из сажи, пепла, масла из двигателя
                  и охлаждающей жидкости, оставаясь при этом безопасным для самого картриджа фильтра.
                </p>
                <p>
                  Гарантийный срок на выполненные работы составляет 6 месяцев или пробег не более 50.000 км
                  в зависимости от того, какое из этих обстоятельств наступит раньше.
                </p>
              </Modal.Body>
            </Modal>
          </Col>

          <Col xs={12} lg={4}>
            <div className={'modals-btn'} role={'button'} onClick={() => setShow(3)}>
              Наши преимущества
            </div>

            <Modal show={show === 3} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Наши преимущества</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul>
                  <li>Не вырезаем, не выжигаем, не разрезаем</li>
                  <li>100% эффективность</li>
                  <li>Продолжительность услуги от 1 часа</li>
                  <li>Удаляем до 99% отложений, накопившихся в фильтре</li>
                  <li>Доставленные самостоятельно фильтры мы почистим прямо</li>
                  <li>на месте</li>
                  <li>Демонтированные фильтры можно выслать к нам курьером, а назад к вам мы отправим их в тот же день</li>
                  <li>После проведения процедуры предоставляются результаты чистки</li>
                  <li>Гарантия на выполненные работы</li>
                </ul>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </div>
  )
}