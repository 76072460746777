import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import YoutubeIframe from "./YoutubeIframe";


function Youtube() {
  return (
    <Row>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <YoutubeIframe src="https://www.youtube.com/embed/lz6mocN8kIc"/>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <YoutubeIframe src="https://www.youtube.com/embed/7TD5thRbTas"/>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <YoutubeIframe src="https://www.youtube.com/embed/xaix-jkj4qo"/>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <YoutubeIframe src="https://www.youtube.com/embed/f6FxmI9DoUI"/>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <YoutubeIframe src="https://www.youtube.com/embed/_iRKJp1F4xY"/>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <YoutubeIframe src="https://www.youtube.com/embed/2-me-HTwz-M"/>
          </Col>
        </Row>
      </Container>
    </Row>
  )
}

export default Youtube;