import * as React from "react";
import Ratio from "react-bootstrap/Ratio";
import {useInView} from 'react-intersection-observer';
import PropTypes from "prop-types";

function YoutubeIframe({src}) {
  const {ref, inView} = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div ref={ref} className="youtube-wrapper">
      {
        inView &&
        <Ratio aspectRatio="16x9">
          <iframe width="560" height="315" src={src}
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen/>
        </Ratio>
      }
    </div>
  )
}

YoutubeIframe.propTypes = {
  src: PropTypes.string.isRequired
}

export default YoutubeIframe;